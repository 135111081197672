// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';


// function GlobalNav() {
//   return (
//     <Navbar expand="lg" className="bg-body-tertiary">
//       <Container>
//         <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="me-auto">
//             <Nav.Link href="#home">Home</Nav.Link>
//             <Nav.Link href="#link">Link</Nav.Link>
//             <NavDropdown title="Dropdown" id="basic-nav-dropdown">
//               <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
//               <NavDropdown.Item href="#action/3.2">
//                 Another action
//               </NavDropdown.Item>
//               <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
//               <NavDropdown.Divider />
//               <NavDropdown.Item href="#action/3.4">
//                 Separated link
//               </NavDropdown.Item>
//             </NavDropdown>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// }

// export default GlobalNav;


import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

import { PencilFill, GearFill } from 'react-bootstrap-icons';

import CDNImage from './CDNImage.js';

import CentralLink from './CentralLink.js';

import loadable from '@loadable/component';

import { TOGGLE_HOMEPAGE_EDIT_MODE } from '../store/reducers/cdvState.js';

import { sendXPAChangeHomePageEditMode } from '../components/GoogleAnalytics.js';

const LoadingInvert = loadable(() => import('./LoadingInvert.js'));

function GlobalNav() {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const navBarCollapseRef = useRef(null);
	const { userId, homePageEditMode } = useSelector(state => state.cdvState);
	const { inFlightRequestsByTrace } = useSelector(state => state.fmAPI);
  const [expanded, setExpanded] = useState(false);



  const handleClickOutside = (event) => {
    if (navBarCollapseRef.current && !navBarCollapseRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  let navBurgerItems = [{
  	label: 'Find a Station',
  	path: '/data-explorer/stationmap/uk'
  }, {
  	label: 'How to use FloodNav',
  	path: '/floodnav/how-to'
  }, {
  	label: 'Copyright and Credits',
  	path: '/copyright-information'
  }, {
  	label: 'Data Accuracy',
  	path: '/data-accuracy'
  }, {
  	label: 'Privacy Policy',
  	path: '/privacy-policy'
  }];

  if(userId) {
  	navBurgerItems.push({
  		label: <span><GearFill /> Manage App Account</span>,
  		path: '/acc-manage'
  	})
  }

  let editModeToggleCallback = null, editModeContent = null;
  if(userId && location.pathname === '/') {
  	/*{userId ? <Row>
					<Col xs={6}>&nbsp;</Col>
					<Col xs={6}><Form.Check onChange={function() {
	        			setEditMode(!editMode);
	        		}} checked={editMode} type="switch" label={<span><PencilFill /> Edit Home Page</span>}/></Col>
				</Row> : null}*/
		editModeToggleCallback = function() {
			setExpanded(false);
			dispatch(TOGGLE_HOMEPAGE_EDIT_MODE());
			sendXPAChangeHomePageEditMode(!homePageEditMode);
		}
		editModeContent = <span><PencilFill /> {homePageEditMode ? "Disable" : "Enable"} Edit Home Page</span>
  }

  let reqInFlight = Object.keys(inFlightRequestsByTrace).reduce(function(acca, k) {
  	return acca + inFlightRequestsByTrace[k];
  }, 0);


  return (
    <Navbar collapseOnSelect expanded={expanded} expand="lg" bg="dark" variant="dark" fixed="top">
      <Container>
        <Navbar.Brand style={{"cursor":"pointer"}} onClick={() => {
          if(typeof window !== 'undefined') {
          	if(window.cordova) {
							navigate('/');	// don't do a hard refresh in the app
          		return;
          	}
          	window.location = `${process.env.REACT_APP_EXTERNAL_PROTO}://www.${process.env.REACT_APP_EXTERNAL_TLD}:${process.env.REACT_APP_EXTERNAL_PORT}`;
          }
        }}>
          <CDNImage assetName='logo-navbar-h140.webp' style={{height: "30px"}} alt="Logo" />
        </Navbar.Brand>
        
         {reqInFlight > 0 ? <Nav.Item>
          <Nav.Link as={CentralLink} to="/your-link" style={{ color: 'white' }}>
            <LoadingInvert style={{"height": "41px", "maxHeight": "41px"}} />
          </Nav.Link>
        </Nav.Item> : null}
        <Navbar.Toggle aria-controls="burger-navbar" className="ml-auto" onClick={() => setExpanded(expanded ? false : "expanded")} />
    	<Navbar.Collapse id="burger-navbar" ref={navBarCollapseRef}>
    		<Nav className="me-auto" style={{color: 'white'}}>
    			<div className="d-lg-none">
	    			{navBurgerItems.map(function(item) {
	    				return <Nav.Link as={CentralLink} to={item.path} key={"mobile-"+item.label} onClick={function() {
	    					setExpanded(false);
	    				}}>{item.label}</Nav.Link>
	    			})}
	    			{/*userId ? <Nav.Link><hr /></Nav.Link> : null*/}
	    			{(userId&&editModeContent) ? <Nav.Link onClick={editModeToggleCallback} style={{color: 'white'}}>{editModeContent}</Nav.Link> : null}
	    		</div>
    		</Nav>
	    	<Nav>
	    		<div className="d-none d-lg-flex" style={{color: 'white'}}>
	    			<NavDropdown title="Site Information" id="site-information-lg-dropdown" align="end" menuVariant="dark">
	    				{navBurgerItems.map(function(item) {
		    				return <NavDropdown.Item key={"desktop-"+item.label} style={{color: 'white'}} onClick={function() {
		    					setExpanded(false);
		    				}}><CentralLink to={item.path}>{item.label}</CentralLink></NavDropdown.Item>
		    			})}
		    			{/*userId ? <NavDropdown.Divider /> : null*/}
		    			{(userId&&editModeContent) ? <NavDropdown.Item onClick={editModeToggleCallback} key={"app-edit-toggle"} style={{color: 'white'}}>{editModeContent}</NavDropdown.Item> : null}
	    			</NavDropdown>
	    		</div>
    		</Nav>
    	</Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default GlobalNav;


// // <Nav>
// //             <Dropdown alignRight>
// //               <Dropdown.Toggle variant="dark" id="dropdown-basic">
// //               	Site Information
// //               </Dropdown.Toggle>

// //               <Dropdown.Menu variant="dark">
// //                 <Dropdown.Item onClick={() => navigate("/privacy-policy")}>
// //                   Privacy Policy
// //                 </Dropdown.Item>
// //               </Dropdown.Menu>
// //             </Dropdown>
// //           </Nav>