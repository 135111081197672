import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { setHydrationComplete } from './store/reducers/csrHandler.js';
import { loadableReady } from '@loadable/component';
import Router from './lib/Router.js'; // looks like a race condition, but it isn't - window.cordova is evaluated at render time
import { CONTEXTS, setAppContext } from './store/reducers/appContext.js';
import { sendXPAResume, sendXPAOpen } from './components/GoogleAnalytics.js';

const handleCSR = function() {
  return loadableReady(async function() {
    const { store } = await import('./store/store.js');
    window.store = store;

    const everythingReady = async function() {
      console.log("<-- Ready -->")


      /* <----- XPA -----> */
      if(window.cordova) {
        // Dynamic imports to keep these out the web bundles
        const { APP_FOCUS_RESUME, APP_SET_SLT, APP_SET_LLT, APP_SET_USER_ID } = await import('./store/reducers/cdvState.js');
        const { reissueSLTUsingLLT } = await import('./store/reducers/fmAPI.js');
        const { Plugins } = await import('@capacitor/core');
        const { SecureStoragePlugin } = Plugins;

        console.log("Seeding store with information about us being on cordova:");
        store.dispatch(setAppContext({ appContext: CONTEXTS.APP_CONTEXT_XPA }));

        try {
          const { value: SLT } = await SecureStoragePlugin.get({ key: 'SLT'});
          const { value: LLT } = await SecureStoragePlugin.get({ key: 'LLT'});
          const { value: USERID } = await SecureStoragePlugin.get({ key: 'USERID' });
          console.log("Bootstrap SLT", SLT);
          store.dispatch(APP_SET_SLT({ slt: SLT }));
          console.log("Bootstrap LLT", LLT);
          store.dispatch(APP_SET_LLT({ llt: LLT }));
          console.log("Bootstrap UserID", USERID);
          store.dispatch(APP_SET_USER_ID( { userId: USERID }));
          // store.dispatch
        } catch(error) {
          console.log("Initial bootstrap failed, user probably not logged in.")
          console.log("Cause: ", error);
        }
        
        if(window.fmuk && window.fmuk.cdvPre) {
          console.log("Bootstrapping fmuk pre cordova plugins");
          console.log("Executing total of " + window.fmuk.cdvPre.length)
          for(let fn of window.fmuk.cdvPre) {
            try {
              fn();
            } catch(error) {
              console.log(JSON.stringify(error));
            }
          }
        }

        const resumeHandler = async function() {
          store.dispatch(APP_FOCUS_RESUME());
          // GA Event: Resume App
          sendXPAResume();  
          // store.dispatch(reissueSLTUsingLLT()); // we can defer this for now and save the api, this isn't needed
        }
        document.addEventListener('resume', resumeHandler, false);

        // Hardware back button
        const { App } = await import('@capacitor/app');
        const { Browser } = await import('@capacitor/browser');
        App.addListener('backButton', (data) => {
          console.log(JSON.stringify(data));
          if(!window.history || !window.history.length || (!data || !data.canGoBack)) {
            return App.minimizeApp();
          }

          if(window.history.length > 1) {
            window.history.back();
          }
        });
        // GA Event: Open App
        sendXPAOpen();
      }
      /* <----- XPA -----> */

      const rootElement = document.getElementById('root');
      if (rootElement.hasChildNodes()) {
        // If the root element already has child nodes, it means the server-side rendered HTML is present.
        console.log("<- Hydrate ->");
        ReactDOM.hydrateRoot(
          rootElement,
          <Provider store={store}>
            <Router>
              <App />
            </Router>
          </Provider>
        );
      } else {
        // If the root element is empty, use render as fallback (though in practice this will be hydrate).
        console.log("<- Traditional Render ->");
        ReactDOM.createRoot(rootElement).render(
          <Provider store={store}>
            <Router>
              <App />
            </Router>
          </Provider>
        );
      }

      console.log("<- Render/Hydrate CSR Complete ->")
      window.removeEventListener('load', handleCSR);
      window.store.dispatch(setHydrationComplete());

        if(window.fmuk && window.fmuk.cdvPost) {
          console.log("Bootstrapping fmuk post cordova plugins");
          console.log("Executing total of " + window.fmuk.cdvPost.length)
          for(let fn of window.fmuk.cdvPost) {
            try {
              fn();
            } catch(error) {
              console.log(JSON.stringify(error));
            }
          }
        }
    };
    // 
    if(!window.cordova) {
      await everythingReady();
    } else {
      console.log("Within a cordova context");
      document.addEventListener('deviceready', everythingReady, false);
    }

  });
}

if(document.readyState === 'complete') {
  console.log("Complete Path")
  handleCSR();
} else {
  console.log("Attach listener for handler");
  window.addEventListener('load', handleCSR);
}

reportWebVitals();
