import React from 'react';
import { Link } from 'react-router-dom';

const CentralLink = ({ to, subdomain = null, dottedUnderline = false, children, ...props }) => {
    let isExternal = true;
    let currentSubdomain = "www";
    if(typeof window !== 'undefined') {
        currentSubdomain = window.location.hostname.split('.')[0];
    }

    let calculatedSubdomain = subdomain ? subdomain : 'www';
    // Construct the target URL for external links
    const externalURL = `${process.env.REACT_APP_EXTERNAL_PROTO}://${calculatedSubdomain}.${process.env.REACT_APP_EXTERNAL_TLD}:${process.env.REACT_APP_EXTERNAL_PORT}${to}`;
    // Determine if the current subdomain is "www", indicating internal navigation
    // based on the assumption that we are external on any link other than www, so we can browse new to floodnav, or browse new back to www
    if (currentSubdomain === "www" && calculatedSubdomain === 'www') {
        isExternal = false;
    }

    // On Cordova/Capacitor we do not cross any domains
    if(typeof window !== 'undefined' && window.cordova) {
        return <Link to={to} {...props}><span style={{textDecoration: (dottedUnderline ? "underline dotted" : null)}}>{children}</span></Link>;
    }

    // Normal web
    if (isExternal) {
        // Use a standard <a> tag for external links, preserving any passed props
        return <a href={externalURL} {...props}><span style={{textDecoration: (dottedUnderline ? "underline dotted" : null)}}>{children}</span></a>;
    } else {
        // Use React Router's <Link> for internal navigation within the SPA
        return <Link to={to} {...props}><span style={{textDecoration: (dottedUnderline ? "underline dotted" : null)}}>{children}</span></Link>;
    }
};

export default CentralLink;
